import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash';
import { Observable, combineLatest, startWith } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { RouterEventsService } from '../services/router-events.service';

import { AbstractModuleNamesService } from './abstract-module-names.service';
import { ModuleNamesMap } from './models';

@Injectable({ providedIn: 'root' })
export class ActiveModuleNameService {
  readonly activeModuleName$: Observable<string> = combineLatest([
    // Initial load of the application does not trigger the navigationEnd event
    this.routerEventsService.navigationEnd$.pipe(startWith({})),
    this.moduleNamesService.appModuleNames$,
    this.moduleNamesService.homeModuleName$,
  ]).pipe(
    map(([, moduleNamesMap, homeModuleName]) => this.getActiveModuleName(moduleNamesMap, homeModuleName)),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );

  constructor(
    private readonly routerEventsService: RouterEventsService,
    private readonly route: ActivatedRoute,
    private readonly moduleNamesService: AbstractModuleNamesService,
  ) {}

  private getActiveModuleName(moduleNamesMap: ModuleNamesMap, homeModuleName: string): string {
    const activeModulePath: string = this.getActiveModuleRoute().snapshot.url[0]?.path || '/';

    return moduleNamesMap[activeModulePath] || homeModuleName;
  }

  private getActiveModuleRoute(): ActivatedRoute {
    // Active module route is always the second child from application root due to the routing structure
    return this.route.firstChild?.firstChild || this.route;
  }
}
