import { Pipe, PipeTransform } from '@angular/core';

import { Optional } from 'utility-types';
import { PrettyPrintListPipeIntl } from './pretty-print-list-intl';

@Pipe({ name: 'prettyPrintList' })
export class PrettyPrintListPipe implements PipeTransform {

  get separator(): string {
    return this.localization.separator;
  }

  get and(): string {
    return this.localization.and;
  }

  get more(): (count: number) => string {
    return this.localization.more ?? ((count: number) => count > 1 ? this.localization.morePlural : this.localization.moreSingular);
  }

  constructor (private localization: PrettyPrintListPipeIntl) {}

  transform(
    items?: Array<string>,
    condenseAfter?: number,
    localization?: Optional<PrettyPrintListPipeIntl> | [string, string]
  ): string | undefined {

    if (!items || condenseAfter === 0) {
      return undefined;
    }

    if (localization) {
      this.partiallyOverrideIntl(localization);
    }

    if (condenseAfter === undefined) {
      return this.stringifyArray(items);  
    }

    const shownItems = items.slice(0, condenseAfter);
    const hiddenItems = items.slice(condenseAfter, items.length);
    const condensedArray = hiddenItems.length ? [...shownItems, `${hiddenItems.length} ${this.more(hiddenItems.length)}`] : shownItems;

    return this.stringifyArray(condensedArray);
  }

  private partiallyOverrideIntl(intl: Optional<PrettyPrintListPipeIntl> | [string, string]): void {
    if (Array.isArray(intl)) {
      const [singular, plural] = intl;
      this.localization.moreSingular = singular;
      this.localization.morePlural = plural;
    } else {
      this.localization = { ...this.localization, ...intl };
    }
  }

  private stringifyArray(items: Array<string>): string | undefined {
    switch (items.length) {
      case 0: return undefined;
      case 1: return this.stringifySingleElement(items);
      case 2: return this.stringifyTwoElements(items);
      default: return this.stringifyThreeOrMoreElements(items);
    }
  }

  private stringifySingleElement(items: Array<string>) {
    return items[0];
  }
  
  private stringifyTwoElements(items: Array<string>): string {
    const [first, last] = items;
    return `${first} ${this.and} ${last}`;
  }
  
  private stringifyThreeOrMoreElements(items: Array<string>): string {
    const last = items.slice(-1);
    const rest = items.slice(0, items.length - 1);
  
    return `${rest.join(', ')} ${this.and} ${last}`;
  }
}
